/* eslint-disable jsx-a11y/anchor-is-valid */
import appStore from '../assets/images/app_store.svg';
import banner from '../assets/images/banner.png';
import playStore from '../assets/images/play_store.svg';

const HeroSection = () => {
  return (
    <section
      className="bg-[url('./assets/images/background.png')] bg-cover bg-center w-full px-10 md:px-40"
      id="hero"
    >
      <div className="flex flex-col-reverse items-center justify-center md:flex-row">
        <div className="py-8 space-y-8 text-left md:flex-1">
          <h1 className="text-[40px] leading-tight font-medium md:leading-none text-center md:text-[70px] md:text-start font-holden">
            Taste the best food
            <div>
              in <span className="text-orange">Myanmar</span>
            </div>
          </h1>
          <p className="text-base font-normal text-center md:text-start">
            One of the key features of this application is its user-friendly
            interface, which allows users to easily search for specific
            restaurants or browse through different categories of food. Users
            can filter their searches by location, price range, and cuisine
            type, making it easy to find exactly what they are looking for.
          </p>
          <div className="flex justify-center space-x-6 md:justify-start">
            <a href="#" className="inline-block">
              <img src={playStore} alt="Play Store" className="h-10 w-30" />
            </a>
            <a href="#" className="inline-block">
              <img src={appStore} alt="App Store" className="h-10 w-30" />
            </a>
          </div>
        </div>
        <div className="md:flex-2">
          <img
            src={banner}
            alt="banner"
            className="md:w-[34rem] md:h-[32rem] w-[364px] h-[290px]"
          />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
