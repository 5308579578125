/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import logo from '../assets/images/logo.png';
import menu from '../assets/images/menu_icon.svg';
import Drawer from './Drawer';
import Nav from './Nav';

const Header = () => {
  const [show, setShow] = useState(false);
  const [active, setActive] = useState('');

  useEffect(() => {
    setActive(window.localStorage.getItem('active') || 'hero');
  }, []);

  const handleActive = (to) => {
    setActive(to);

    window.localStorage.setItem('active', to);
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [show]);

  const scrollToTop = () => {
    scroll.scrollToTop();
    setShow(false);
  };

  return (
    <header className="sticky top-0 px-10 bg-white md:px-40">
      {show && (
        <div className="fixed top-0 right-0 z-50 w-full h-screen overflow-hidden bg-black/30">
          <div className="flex h-full">
            <div className="w-1/5 bg-transparent"></div>
            <div className="w-4/5 h-full bg-white">
              <div className="flex items-center justify-between px-8 py-2">
                <button
                  onClick={() => setShow(false)}
                  className="block md:hidden"
                >
                  <img src={menu} alt="menu" className="w-8 h-8" />
                </button>
                <div>
                  <a href="#" onClick={scrollToTop}>
                    <img
                      src={logo}
                      alt="logo"
                      className="w-24 h-24 md:w-28 md:h-28"
                    />
                  </a>
                </div>
              </div>
              <Drawer setShow={setShow} />
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center justify-between">
        <div>
          <a href="#" onClick={scrollToTop}>
            <img src={logo} alt="logo" className="w-24 h-24 md:w-28 md:h-28" />
          </a>
        </div>
        <button
          onClick={() => setShow((show) => !show)}
          className="block md:hidden"
        >
          <img src={menu} alt="menu" className="w-8 h-8" />
        </button>
        <nav className="hidden space-x-6 md:flex">
          <Nav
            to="hero"
            label="Home"
            onActive={handleActive}
            active={active === 'hero'}
          />
          <Nav
            to="foods"
            label="Foods"
            onActive={handleActive}
            active={active === 'foods'}
          />
          <Nav
            to="reviews"
            label="Reviews"
            onActive={handleActive}
            active={active === 'reviews'}
          />
          <Nav
            to="about"
            label="About"
            onActive={handleActive}
            active={active === 'about'}
          />
          <Nav
            to="contacts"
            label="Contact Us"
            onActive={handleActive}
            active={active === 'contacts'}
          />
        </nav>
      </div>
    </header>
  );
};

export default Header;
