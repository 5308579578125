import { Outlet, RouterProvider } from 'react-router-dom';
import router from './routes';

const App = () => {
  return (
    <RouterProvider router={router}>
      <Outlet></Outlet>
    </RouterProvider>
  );
};

export default App;
