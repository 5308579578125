import hat from '../assets/images/hat.png';
import Link from './Link';

const Nav = ({ to, label, onActive, active }) => {
  return (
    <div>
      <div className="relative group">
        <Link
          to={to}
          className={`inline-block px-6 py-4 text-lg font-medium uppercase group-hover:text-orange ${
            active ? 'text-orange' : ''
          }`}
          label={label}
          onClick={() => onActive(to)}
        />

        <div
          className={`absolute z-10 group-hover:block -top-1 left-2 ${
            active ? 'block' : 'hidden'
          }`}
        >
          <img src={hat} alt="hat" />
        </div>
      </div>
    </div>
  );
};

export default Nav;
