/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import Link from './Link';

const Drawer = ({ setShow }) => {
  const [active, setActive] = useState('');

  useEffect(() => {
    setActive(window.localStorage.getItem('active') || 'hero');
  }, []);

  const handleActive = (to) => {
    setActive(to);

    window.localStorage.setItem('active', to);

    setShow(false);
  };

  return (
    <nav className="divide-y divide-orangeLight">
      <div>
        <Link
          onClick={() => handleActive('hero')}
          to="hero"
          label="Home"
          className={`${
            active === 'hero' ? 'bg-orangeLight text-orange' : ''
          } block px-3 py-6 text-lg text-black uppercase hover:bg-orangeLight hover:text-orange`}
        />
      </div>
      <div>
        <Link
          onClick={() => handleActive('foods')}
          to="foods"
          label="Foods"
          className={`${
            active === 'foods' ? 'bg-orangeLight text-orange' : ''
          } block px-3 py-6 text-lg text-black uppercase hover:bg-orangeLight hover:text-orange`}
        />
      </div>
      <div>
        <Link
          onClick={() => handleActive('reviews')}
          to="reviews"
          label="Reviews"
          className={`${
            active === 'reviews' ? 'bg-orangeLight text-orange' : ''
          } block px-3 py-6 text-lg text-black uppercase hover:bg-orangeLight hover:text-orange`}
        />
      </div>
      <div>
        <Link
          onClick={() => handleActive('about')}
          to="about"
          label="About"
          className={`${
            active === 'about' ? 'bg-orangeLight text-orange' : ''
          } block px-3 py-6 text-lg text-black uppercase hover:bg-orangeLight hover:text-orange`}
        />
      </div>
      <div>
        <Link
          onClick={() => handleActive('contacts')}
          to="contacts"
          label="Contact Us"
          className={`${
            active === 'contacts' ? 'bg-orangeLight text-orange' : ''
          } block px-3 py-6 text-lg text-black uppercase hover:bg-orangeLight hover:text-orange`}
        />
      </div>
    </nav>
  );
};
export default Drawer;
