import about from '../assets/images/about.png';
import ellipse from '../assets/images/ellipse.svg';

const AboutSection = () => {
  return (
    <section className="px-10 pt-8 text-center md:px-40" id="about">
      <h2 className="mb-6 text-4xl font-normal md:text-6xl font-holden">
        <span className="text-orange">About</span> The Application
      </h2>
      <p className="text-base font-normal leading-loose md:text-lg">
        This application is the ultimate resource for finding the best
        restaurants and dishes in your area. With thousands of user-generated
        reviews, ratings, and photos, you can easily discover new restaurants,
        read honest opinions, and make informed dining decisions.
      </p>
      <div className="flex flex-col items-center justify-between mt-4 space-y-6 md:flex-row md:space-y-0">
        <div className="flex-1 space-y-6">
          <section className="text-left">
            <h3 className="text-lg font-semibold leading-normal md:text-2xl">
              Besides you can read and write reviews, you can also make Plan to
              eat food in your holiday!
            </h3>
            <div>
              <div className="flex items-baseline space-x-3">
                <img src={ellipse} alt="ellipse" className="w-3" />
                <p className="text-base font-normal leading-loose md:mt-2 md:text-lg">
                  Choose the restaurant that you want to go later.
                </p>
              </div>
              <div className="flex items-baseline space-x-3">
                <img src={ellipse} alt="ellipse" className="w-3" />
                <p className="text-base font-normal leading-loose md:mt-2 md:text-lg">
                  Choose favorite food menu.
                </p>
              </div>
              <div className="flex items-baseline space-x-3">
                <img src={ellipse} alt="ellipse" className="w-3" />
                <p className="text-base font-normal leading-loose md:mt-2 md:text-lg">
                  Choose date and time to eat later.
                </p>
              </div>
              <div className="flex items-baseline space-x-3">
                <img src={ellipse} alt="ellipse" className="w-3" />
                <p className="text-base font-normal leading-loose md:mt-2 md:text-lg">
                  Make plan to go and eat with friends, family or others.
                </p>
              </div>
            </div>
          </section>
          <section className="text-left">
            <h3 className="mb-2 text-lg font-semibold leading-loose md:text-2xl">
              Easy to use user-friendly Interface
            </h3>
            <p className="mb-3 text-base font-normal leading-normal md:text-lg">
              The interface is designed to be intuitive and user-friendly,
              making it easy for users to search for and discover new
              restaurants and food options.
            </p>
            <p className="text-base font-normal leading-normal md:text-lg">
              The restaurant listings provide a wealth of information, including
              photos, reviews, menus, and contact information. The interface is
              designed to be easy to read and visually appealing, with large
              photos and clear typography.
            </p>
          </section>
        </div>
        <div className="flex justify-end flex-1">
          <img
            src={about}
            alt="About"
            className="md:w-[480px] md:h-[480px] w-[306px] h-[306px]"
          />
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
