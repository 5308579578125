import berries from '../assets/images/berries.png';
import birdNest from '../assets/images/bird_nest.png';
import food from '../assets/images/food_image.png';
import meat from '../assets/images/meat.png';
import myanmarFood from '../assets/images/myanmar_food.png';
import organic from '../assets/images/organic.png';
import star from '../assets/images/star.svg';

const FoodSection = () => {
  return (
    <section className="px-10 pt-8 text-center md:px-40" id="foods">
      <h2 className="mb-6 text-4xl font-normal md:text-5xl font-holden">
        Enjoy Tasty <span className="text-orange">Food</span>, Get Healthy Life
      </h2>
      <p className="text-base font-normal leading-loose md:text-lg">
        This application offers a wide selection of delicious and healthy food
        options to help you maintain a balanced and nutritious diet.
      </p>
      <p className="text-base font-normal leading-loose md:text-lg">
        Whether you're looking for a lunch on-the-go or a full meal for dinner,
        our application has everything you need to eat well and feel your best.
      </p>
      <div className="my-6">
        <img src={food} alt="Foods" className="w-full h-auto" />
      </div>
      <div className="flex justify-center space-x-2 md:space-x-4">
        <div className="space-y-2">
          <div className="rounded-lg">
            <img
              src={berries}
              alt="Berries"
              className="md:w-[170px] w-[100px] rounded-2xl md:h-[170px] h-[100px]"
            />
          </div>
          <div className="flex flex-col space-y-2">
            <p className="text-[15px] font-normal md:text-2xl">Black Berries</p>
            <div className="flex justify-center space-x-2 text-center">
              <img src={star} className="w-3 md:w-4" alt="star" />
              <p className="text-[15px] font-normal md:text-2xl text-orange">
                5.0
              </p>
            </div>
          </div>
        </div>
        <div className="space-y-2">
          <div className="rounded-lg">
            <img
              src={organic}
              alt="Organic"
              className="md:w-[170px] w-[100px] rounded-2xl md:h-[170px] h-[100px]"
            />
          </div>
          <div className="flex flex-col space-y-2">
            <p className="text-[15px] font-normal md:text-2xl">Organic Food</p>
            <div className="flex justify-center space-x-2 text-center">
              <img src={star} className="w-3 md:w-4" alt="star" />
              <p className="text-[15px] font-normal md:text-2xl text-orange">
                5.0
              </p>
            </div>
          </div>
        </div>
        <div className="space-y-2">
          <div className="rounded-lg">
            <img
              src={birdNest}
              alt="Bird Nest"
              className="md:w-[170px] w-[100px] rounded-2xl md:h-[170px] h-[100px]"
            />
          </div>
          <div className="flex flex-col space-y-2">
            <p className="text-[15px] font-normal md:text-2xl">Bird's Nest</p>
            <div className="flex justify-center space-x-2 text-center">
              <img src={star} className="w-3 md:w-4" alt="star" />
              <p className="text-[15px] font-normal md:text-2xl text-orange">
                5.0
              </p>
            </div>
          </div>
        </div>
        <div className="hidden space-y-2 md:block">
          <div className="rounded-lg">
            <img
              src={myanmarFood}
              alt="Myanmar Food"
              className="md:w-[170px] w-[100px] rounded-2xl md:h-[170px] h-[100px]"
            />
          </div>
          <div className="flex flex-col space-y-2">
            <p className="text-[15px] font-normal md:text-2xl">Myanmar Food</p>
            <div className="flex justify-center space-x-2 text-center">
              <img src={star} className="w-3 md:w-4" alt="star" />
              <p className="text-[15px] font-normal md:text-2xl text-orange">
                5.0
              </p>
            </div>
          </div>
        </div>
        <div className="hidden space-y-2 md:block">
          <div className="rounded-lg">
            <img
              src={meat}
              alt="Meat"
              className="md:w-[170px] w-[100px] rounded-2xl md:h-[170px] h-[100px]"
            />
          </div>
          <div className="flex flex-col space-y-2">
            <p className="text-[15px] font-normal md:text-2xl">Fresh Meat</p>
            <div className="flex justify-center space-x-2 text-center">
              <img src={star} className="w-3 md:w-4" alt="star" />
              <p className="text-[15px] font-normal md:text-2xl text-orange">
                5.0
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FoodSection;
