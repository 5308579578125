import girl from '../assets/images/girl.png';
import stars from '../assets/images/stars.png';
import user1 from '../assets/images/user_1.png';
import user2 from '../assets/images/user_2.png';
import user3 from '../assets/images/user_3.png';
import user4 from '../assets/images/user_4.png';

const CountsSection = () => {
  return (
    <section className="px-10 pt-8 md:px-40">
      <div className="flex flex-col items-center justify-center space-y-4 md:space-y-0 md:justify-start md:flex-row md:space-x-52">
        <div className="flex flex-col items-center justify-center">
          <div className="mb-2">
            <img src={stars} alt="ratings" className="w-24" />
          </div>
          <div className="flex mb-3 space-x-8 md:mb-4">
            <div>
              <img src={user1} alt="User 1" className="h-[70px] w-[70px]" />
            </div>
            <div>
              <img src={user2} alt="User 2" className="w-[70px] h-[70px]" />
            </div>
            <div>
              <img src={user3} alt="User 3" className="w-[70px] h-[70px]" />
            </div>
            <div>
              <img src={user4} alt="User 4" className="w-[70px] h-[70px]" />
            </div>
          </div>
          <div className="space-y-1 md:space-y-2">
            <div className="text-xl md:text-2xl text-orange">7K+</div>
            <div className="text-base font-normal">Reviews</div>
          </div>
        </div>
        <div className="flex items-end justify-end space-x-2 md:space-x-3">
          <div>
            <img src={girl} alt="Girl" className="w-[269px]" />
          </div>
          <div className="space-y-2 md:space-y-4 h-fit">
            <div className="space-y-1 md:space-y-2">
              <div className="text-xl md:text-2xl text-orange">300+</div>
              <div className="text-base font-normal">Happy Users</div>
            </div>
            <div className="space-y-1 md:space-y-2">
              <div className="text-xl md:text-2xl text-orange">1K+</div>
              <div className="text-base font-normal">Food Reviews</div>
            </div>
            <div className="space-y-1 md:space-y-2">
              <div className="text-xl md:text-2xl text-orange">2K+</div>
              <div className="text-base font-normal">
                Restaurant and Shop Reviews
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CountsSection;
