import { createBrowserRouter } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import TermAndConditionPage from '../pages/TermAndConditionPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/terms-and-conditions',
    element: <TermAndConditionPage />,
  },
]);

export default router;
