/* eslint-disable jsx-a11y/anchor-is-valid */
// import { useEffect, useState } from 'react';
// import URL, { PATHNAME } from './constants/api';
// import http from './networks/api';

import AboutSection from '../components/AboutSection';
import ContactUsSection from '../components/ContactUsSection';
import CountsSection from '../components/CountsSection';
import FoodSection from '../components/FoodSection';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import ReviewSection from '../components/ReviewSection';

// const { WEBSITE } = PATHNAME;

const HomePage = () => {
  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const data = await http().get(`${URL}${WEBSITE}`);
  //     setData(data);
  //   };

  //   fetchData();
  // }, []);

  return (
    <div className="font-body scroll-smooth">
      <Header />
      <HeroSection />
      <CountsSection />
      <FoodSection />
      <ReviewSection />
      <AboutSection />
      <ContactUsSection />
    </div>
  );
};

export default HomePage;
