import { Link as ScrollLink } from 'react-scroll';

const Link = ({ to, className, label, onClick = null }) => {
  return (
    <ScrollLink
      onClick={onClick}
      to={to}
      className={className}
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
    >
      {label}
    </ScrollLink>
  );
};

export default Link;
