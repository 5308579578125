import reviews from '../assets/images/revews.png';
import review1 from '../assets/images/review1.png';
import review2 from '../assets/images/review2.png';
import review3 from '../assets/images/review3.png';
import review4 from '../assets/images/review4.png';

const ReviewSection = () => {
  return (
    <section className="px-10 pt-8 text-center md:px-40" id="reviews">
      <h2 className="mb-6 text-4xl font-normal md:text-6xl font-holden">
        Honest <span className="text-orange">Reviews</span> Bring to Great Food
      </h2>
      <p className="text-base font-normal leading-loose md:text-lg">
        Users can rate different aspects of the food, service, atmosphere, &
        leave detailed comments about their experiences.
      </p>
      <p className="text-base font-normal leading-loose md:text-lg">
        This information is then available for other users to read, providing
        valuable insights and recommendations for where to eat in Myanmar.
      </p>
      <p className="text-base font-normal leading-loose md:text-lg">
        This creates a community of food enthusiasts who can share their love of
        food and help others discover exciting places to eat.
      </p>
      <div className="hidden mt-6 md:block">
        <img src={reviews} alt="reviews" className="w-full h-auto" />
      </div>
      <div className="block mt-6 space-y-3 md:hidden">
        <img src={review1} alt="review1" className="w-full h-auto" />
        <img src={review2} alt="review2" className="w-full h-auto" />
        <img src={review3} alt="review3" className="w-full h-auto" />
        <img src={review4} alt="review4" className="w-full h-auto" />
      </div>
    </section>
  );
};

export default ReviewSection;
