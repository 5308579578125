/* eslint-disable jsx-a11y/anchor-is-valid */
import facebook from '../assets/images/facebook.svg';
import telegram from '../assets/images/telegram.svg';
import twitter from '../assets/images/twitter.svg';

const ContactUsSection = () => {
  return (
    <section
      className="pt-8 mt-8 text-center bg-gradient-to-r from-[#FF5C0038] to-[#FF5C0033]"
      id="contacts"
    >
      <div className="flex md:flex-row flex-col justify-center mx-10 md:mx-40 border-b-[1px] border-orange pb-6 space-y-6 md:space-y-0">
        <div className="flex-1 space-y-4 text-center">
          <h3 className="text-lg font-semibold md:font-medium md:text-2xl text-orange">
            Food@MM
          </h3>
          <p className="text-base font-normal leading-normal md:text-lg md:leading-loose">
            Using the application, let's see the latest popular restaurants and
            food. Let's make plans to eat with friends, family or our beloved
            ones.
          </p>
        </div>
        <div className="flex-1 space-y-4 text-center">
          <h3 className="text-lg font-semibold md:text-2xl md:font-medium text-orange">
            Contact Us
          </h3>
          <div className="text-base font-normal leading-normal md:text-lg md:leading-loose">
            <p>Phone - 09257598895</p>
            <p>Email - foodatmm@gmail.com</p>
          </div>
        </div>
        <div className="flex-1 space-y-4 text-center">
          <h3 className="text-lg font-semibold md:text-2xl md:font-medium text-orange">
            Follow Us On
          </h3>
          <div className="flex justify-center space-x-8 md:space-x-4">
            <a href="#" target="_blank">
              <img
                src={telegram}
                alt="Telegram"
                className="w-8 h-8 md:w-10 md:h-10"
              />
            </a>
            <a href="#" target="_blank">
              <img
                src={twitter}
                alt="Twitter"
                className="w-8 h-8 md:w-10 md:h-10"
              />
            </a>
            <a href="#" target="_blank">
              <img
                src={facebook}
                alt="Facebook"
                className="w-8 h-8 md:w-10 md:h-10"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="py-3 text-xs font-normal leading-loose text-center md:text-sm">
        © 2023 food@mm. All Rights Reserved.
      </div>
    </section>
  );
};

export default ContactUsSection;
